import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { ProductCardProduct } from '../@types/ProductCardProduct';
import PriceFormatted from '../PriceFormatted';

export interface ProductCardPriceProps extends StandardDivProps<ProductCardPriceClassKey> {
  currency: string;
  product: ProductCardProduct;
}

export type ProductCardPriceClassKey = 'root';

const themeSettings = { name: 'ProductCardPrice' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductCardPriceClassKey, {}>({
      root: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
        marginBottom: theme.spacing(1.5),
      },
    }),
  themeSettings,
);

const ProductCardPrice = React.forwardRef<HTMLDivElement, ProductCardPriceProps>(
  ({ className, classes: pClasses, currency, product }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <PriceFormatted value={product.priceWithTax} currency={currency} />
      </div>
    );
  },
);

ProductCardPrice.displayName = 'ProductCardPrice';

export default withThemeProps(themeSettings)(ProductCardPrice);
