import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { ProductCardProduct } from '../@types/ProductCardProduct';

export interface ProductCardManufacturerProps extends StandardDivProps<ProductCardManufacturerClassKey> {
  product: ProductCardProduct;
}

export type ProductCardManufacturerClassKey = 'root';

const themeSettings = { name: 'ProductCardManufacturer' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductCardManufacturerClassKey, {}>({
      root: {
        ...theme.typography.body1,
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(16),
      },
    }),
  themeSettings,
);

const ProductCardManufacturer = React.forwardRef<HTMLDivElement, ProductCardManufacturerProps>(
  ({ className, classes: pClasses, product }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {product.manufacturer?.name}
      </div>
    );
  },
);

ProductCardManufacturer.displayName = 'ProductCardManufacturer';

export default withThemeProps(themeSettings)(ProductCardManufacturer);
