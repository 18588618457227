import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import React from 'react';
import { ProductCardProduct } from '../@types/ProductCardProduct';
import ProductStockQuantityPreview from '../ProductStockQuantityPreview';

export interface ProductCardStockAvailabilityProps extends StandardDivProps<ProductCardStockAvailabilityClassKey> {
  product: ProductCardProduct;
}

export type ProductCardStockAvailabilityClassKey = never;

const ProductCardStockAvailability = React.forwardRef<HTMLDivElement, ProductCardStockAvailabilityProps>(
  ({ className, product }, ref) => {
    return <ProductStockQuantityPreview ref={ref} className={className} product={product} />;
  },
);

ProductCardStockAvailability.displayName = 'ProductCardStockAvailability';

export default ProductCardStockAvailability;
