import React from 'react';
import { useRecoilValue } from 'recoil';
import { ProductCardProduct } from '../components/@types/ProductCardProduct';
import { localeState } from './appState';
import { useProductParametersConvertToDisplay } from './products/productParameters';
import { productParameterTypesQuery } from './products/productParameterTypes';

const useProductCardParameters = (
  parameters: ProductCardProduct['parameters'],
): [showParams: false] | [showParams: true, fuel: string, wet: string, noise: string] => {
  const nextParameters = useProductParametersConvertToDisplay(
    useRecoilValue(localeState),
    useRecoilValue(productParameterTypesQuery),
    parameters,
  );

  return React.useMemo(() => {
    const toDisplayCodes = ['/rollingResistance', '/breakingDistanceWet', '/noise'];
    const [fuel, wet, noise] = toDisplayCodes
      .map((dc) => (nextParameters || []).find((p) => p.code === dc))
      .filter(Boolean);

    return fuel?.value && wet?.value && noise?.value
      ? [true, fuel.value as string, wet.value as string, noise.value as string]
      : [false];
  }, [nextParameters]);
};

export default useProductCardParameters;
