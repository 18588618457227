import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProductCardProduct } from '../@types/ProductCardProduct';
import ProductCardImage from '../ProductCardImage';
import ProductCardInlineParams from '../ProductCardInlineParams';
import ProductCardManufacturer from '../ProductCardManufacturer';
import ProductCardName from '../ProductCardName';
import ProductCardParameters from '../ProductCardParameters';
import ProductCardPrice from '../ProductCardPrice';
import ProductCardStockAvailability from '../ProductCardStockAvailability';
import ProductCardTag from '../ProductCardTag';

export interface ProductCardProps extends StandardDivProps<ProductCardClassKey> {
  currency: string;
  product: ProductCardProduct;
}

export type ProductCardClassKey = 'root' | 'stock' | 'container' | 'paramContainer';

const themeSettings = { name: 'ProductCard' };

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductCardClassKey, {}>({
      root: {
        flexGrow: 1,
        minWidth: theme.spacing(36),
        maxWidth: theme.spacing(40),
        height: theme.spacing(48),
        position: 'relative',
        border: `1px solid ${theme.palette.divider}`,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'all .2s ease',

        '&:hover': {
          boxShadow: theme.shadows[4],
          textDecoration: 'none',
        },
      },
      stock: {
        paddingTop: 0,
      },
      container: {
        padding: theme.spacing(2),
        paddingBottom: 0,
      },
      paramContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      },
    }),
  themeSettings,
);

const ProductCard: React.FC<ProductCardProps> = React.forwardRef(
  ({ className, classes: pClasses, currency, product }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const history = useHistory();

    const handleRedirect = React.useCallback(() => {
      history.push(product.href);
    }, [history, product.href]);

    return (
      <Link
        ref={ref}
        component='div'
        aria-hidden='true'
        className={clsx(classes.root, className)}
        onClick={handleRedirect}
      >
        <div className={classes.container}>
          <ProductCardManufacturer product={product} />
          <ProductCardName product={product} />
          <ProductCardInlineParams product={product} />
          <ProductCardPrice product={product} currency={currency} />
          <ProductCardStockAvailability product={product} classes={{ root: classes.stock }} />
        </div>
        <div className={classes.paramContainer}>
          <Hidden smDown>
            <ProductCardParameters product={product} />
          </Hidden>
          <ProductCardImage product={product} />
        </div>
        <ProductCardTag product={product} />
      </Link>
    );
  },
);

ProductCard.displayName = 'ProductCard';

export default withThemeProps(themeSettings)(ProductCard);
