import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import useProductCardParameters from '../../api/useProductCardParameters';
import { ProductCardProduct } from '../@types/ProductCardProduct';
import FuelClassIcon from '../icons/FuelClassIcon';
import NoiseClassIcon from '../icons/NoiseClassIcon';
import WetGripClassIcon from '../icons/WetGripClassIcon';

export interface ProductCardParametersProps extends StandardDivProps<ProductCardParametersClassKey> {
  product: ProductCardProduct;
}

export type ProductCardParametersClassKey = 'root';

const themeSettings = { name: 'ProductCardParameters' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductCardParametersClassKey, {}>({
      root: {
        padding: theme.spacing(1, 2),
        marginBottom: theme.spacing(3),
        width: theme.spacing(10),
        paddingBottom: theme.spacing(4),
      },
    }),
  themeSettings,
);

const ProductCardParameters = React.forwardRef<HTMLDivElement, ProductCardParametersProps>(
  ({ className, classes: pClasses, product }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const [showParams, fuelParamValue, wetParamValue, noiseParamValue] = useProductCardParameters(product.parameters);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {showParams && (
          <>
            <ParameterItem icon={FuelClassIcon} value={fuelParamValue} title='Třída palivové účinnosti' />
            <ParameterItem icon={WetGripClassIcon} value={wetParamValue} title='Třída přilnavosti za mokra' />
            <ParameterItem
              icon={NoiseClassIcon}
              value={noiseParamValue}
              title='Třídy a naměřená hodnota vnějšího hluku odvalování'
              noBorder
            />
          </>
        )}
      </div>
    );
  },
);

ProductCardParameters.displayName = 'ProductCardParameters';

export default withThemeProps(themeSettings)(ProductCardParameters);

const ParameterItem = withStyles(
  (theme: Theme) => ({
    root: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(0.5, 0),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    },
    border: {
      borderBottom: 'none',
    },
  }),
  { name: 'ParameterItem' },
)(
  ({
    classes,
    className,
    icon: Icon,
    value,
    title,
    noBorder,
  }: {
    classes: Record<'root' | 'border', string>;
    className?: string;
    icon: React.ElementType;
    noBorder?: boolean;
    title: string;
    value: React.ReactNode;
  }) => {
    return (
      <Tooltip title={title}>
        <div className={clsx(classes.root, className, { [classes.border]: noBorder })}>
          <Icon color='inherit' />
          {value}
        </div>
      </Tooltip>
    );
  },
);
