export const useProductCardAdornmets = <P extends { taxonomies?: string[] | undefined }>(
  product: P,
): 'sale' | 'new' | 'action' | 'cashback' | 'both' | undefined => {
  const { taxonomies } = product;

  if ((taxonomies || []).includes('/new')) return 'new';
  if ((taxonomies || []).includes('/sale')) return 'sale';
  if ((taxonomies || []).includes('/action')) return 'action';
  if ((taxonomies || []).includes('/sale') && (taxonomies || []).includes('/cashback')) return 'both';
  if ((taxonomies || []).includes('')) return 'action';
  return undefined;
};
