import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ProductImage from '@sprinx/react-com/ProductImage';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { ProductCardProduct } from '../@types/ProductCardProduct';

export interface ProductCardImageProps extends StandardDivProps<ProductCardImageClassKey> {
  product: ProductCardProduct;
}

export type ProductCardImageClassKey = 'root' | 'imageRoot' | 'image';

const themeSettings = { name: 'ProductCardImage' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductCardImageClassKey, {}>({
      root: {
        width: theme.spacing(22),
        height: theme.spacing(16),
        position: 'relative',
        overflow: 'hidden',
        marginLeft: 'auto',
      },
      imageRoot: {
        width: '100%',
        height: '100%',
      },
      image: { width: '100%', height: 'auto', display: 'block' },
    }),
  themeSettings,
);

const ProductCardImage = React.forwardRef<HTMLDivElement, ProductCardImageProps>(
  ({ className, classes: pClasses, product }, ref) => {
    const classes = useStyles({ classes: pClasses, className });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <ProductImage
          alt={product.name[0].text}
          shape='portrait'
          shrink='fit'
          size='small'
          src={product.image}
          mediaSizeMap={{ small: 'original' }}
          classes={{
            root: classes.imageRoot,
            image: classes.image,
          }}
        />
      </div>
    );
  },
);

ProductCardImage.displayName = 'ProductCardImage';

export default withThemeProps(themeSettings)(ProductCardImage);
