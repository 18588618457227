import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ProductCardProduct } from '../@types/ProductCardProduct';

export interface ProductCardNameProps extends StandardDivProps<ProductCardNameClassKey> {
  product: ProductCardProduct;
}

export type ProductCardNameClassKey = 'root' | 'text';

const themeSettings = { name: 'ProductCardName' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductCardNameClassKey, {}>({
      root: {
        width: '100%',
        ...theme.typography.h1,
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(0.5),
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'none',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: theme.typography.pxToRem(15),
        },
      },
      text: { height: 23, overflow: 'hidden', width: '100%' },
    }),
  themeSettings,
);

const ProductCardName = React.forwardRef<HTMLDivElement, ProductCardNameProps>(
  ({ className, classes: pClasses, product }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslate();

    return (
      <div ref={ref} className={classes.text}>
        <Link
          component={RouterLink}
          to={product.href}
          className={clsx(classes.root, className)}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            return true;
          }}
        >
          {t(product.name, { acceptString: true })}
        </Link>
      </div>
    );
  },
);

ProductCardName.displayName = 'ProductCardName';

export default withThemeProps(themeSettings)(ProductCardName);
